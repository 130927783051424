import { inject, Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import mixpanel, { Config, Dict } from 'mixpanel-browser';
import { distinctUntilChanged, tap } from 'rxjs';

import { BaseUserData, UserRole } from '@@core/models/auth/auth-model';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';
import { ConfigurationStore } from '@@shared/stores/configuration-store/stores/configuration.store';

import { AnalyticsPayloadContextProvider } from '../../../features/tia/models/tia-analytics.model';
import { PayloadTransformer } from '../../models/analytics/analytics.model';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	#isLoggedIn = false;
	#userRole: string;
	#initialized = false;
	#initCalled = false;
	readonly #userDetails$ = toObservable(inject(AuthStore).user); // Refactor this in future with signal reactivity when this service will be ready
	readonly #authStore = inject(AuthStore);
	readonly #applicationConfig = inject(APP_CONFIG);
	readonly #configurationStore = inject(ConfigurationStore);

	public track(action: string, payload: Dict = {}): void {
		if (this.#isLoggedIn && this.#userRole as UserRole !== UserRole.SealightsAdmin && this.#initialized) {
			mixpanel.track(action, payload);
		}
	}

	/*
		trackSafe is a safe variant of track method. This implementation will automatically catch exceptions that may arise during data collection.
	 */
	public trackSafe<T = unknown>(
		action: string,
		transformer?: PayloadTransformer<T> | undefined,
		payloadContextProvider?: AnalyticsPayloadContextProvider<T>): void {
		try {
			const payloadContext: T = payloadContextProvider?.();
			if (transformer) {
				void Promise.resolve(transformer(payloadContext)).then(payload => this.track(action, payload));
			} else {
				this.track(action);
			}
		} catch (e) {
			console.log('Analytics Track Error', e);
		}
	}

	init(): void {
		if (this.#initCalled) {
			return;
		}
		this.#initCalled = true;
		this.#userDetails$
			.pipe(
				tap(user => {
					if (user && !this.#initialized) {
						mixpanel.init('empty', this.#prepareConfig());
						this.#initialized = true;
					}
				}),
				distinctUntilChanged((prev, curr) => prev?.email === curr?.email))
			.subscribe((user) => {
				if (user) {
					this.#onLogin(user);
				} else {
					this.#onLogout();
				}
			});
	}

	#prepareConfig(): Partial<Config> {
		const analyticConfig = this.#configurationStore.analytics();
		const apiBaseUrl = this.#applicationConfig.uri.apiBaseUrl;

		return {
			api_host: `${apiBaseUrl}v1/analytics`,
			xhr_headers: {
				Authorization: `Bearer ${this.#authStore.getAccessToken()}`
			},
			debug: analyticConfig.debug,
		};
	}

	#onLogin(user: BaseUserData): void {
		this.#isLoggedIn = true;
		this.#userRole = user.role;
		if (this.#userRole as UserRole !== UserRole.SealightsAdmin) {
			mixpanel.set_config(this.#prepareConfig());
			mixpanel.identify(user.email);
		}
	}

	#onLogout(): void {
		this.#isLoggedIn = false;
		this.#userRole = null;
		this.#initCalled = false;
		this.#initialized = false;
		mixpanel.reset();
		mixpanel.set_config(this.#prepareConfig());
	}
}
